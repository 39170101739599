import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { Grid, Paper, Typography } from "@mui/material";
import React from "react";
import DropIcon from "../../assets/svg/DropIcon";
import MPDIcon from "../../assets/svg/MPDIcon";
import "../../CustomerRedemption/dashboard_revamp/dashboard.css";
import { formatDate } from "../../CustomerRedemption/dashboard_revamp/dashboard.utils";
import { formattedNumber, toPascalCase } from "../../utils";

const SchemeCard = ({ item, inactive, onSchemaClick }) => {
  return (
    <Grid item xs={12} sm={6} md={4} xl={3}>
      <Paper
        elevation={4}
        className="scheme-paper"
        sx={
          inactive
            ? { borderRadius: "10px", paddingBottom: "24px" }
            : {
                borderRadius: "10px",
                paddingBottom: "24px",
                cursor: "pointer",
              }
        }
        onClick={() => onSchemaClick(item?.offerid)}
      >
        <div className="paper-sections" style={{ marginBottom: "24px" }}>
          <Typography sx={{ fontSize: 20, fontWeight: 600, color: "#1A1A1A" }}>
            {toPascalCase(item?.offername)}
          </Typography>
          <Typography sx={{ fontSize: 16, fontWeight: 400, color: "#666666" }}>
            From {formatDate(item?.effectivefrom)} to{" "}
            {formatDate(item?.effectivetill)}
          </Typography>
        </div>
        <hr className="paper-hr" />
        <Grid container>
          <Grid item xs={6} style={{ padding: "24px 0px 24px 0px" }}>
            <div className="paper-sections">
              {item?.slab_type === "volume" ? (
                <>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <MPDIcon color="#0067AC" height="24" width="24" />
                    <Typography
                      sx={{
                        fontSize: 16,
                        fontWeight: 400,
                        color: "#666666",
                        marginLeft: "8px",
                      }}
                    >
                      Total Fuel
                    </Typography>
                  </div>
                  <Typography
                    sx={{
                      fontSize: 18,
                      fontWeight: 600,
                      color: "#1A1A1A",
                      marginTop: "8px",
                    }}
                  >
                    {formattedNumber(item?.totalCumulativeFueling)} L
                  </Typography>
                </>
              ) : (
                <>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <CurrencyRupeeIcon
                      style={{ color: "#0067AC", fontSize: 24 }}
                    />
                    <Typography
                      sx={{
                        fontSize: 16,
                        fontWeight: 400,
                        color: "#666666",
                        marginLeft: "8px",
                      }}
                    >
                      Total Amount
                    </Typography>
                  </div>
                  <Typography
                    sx={{
                      fontSize: 18,
                      fontWeight: 600,
                      color: "#1A1A1A",
                      marginTop: "8px",
                    }}
                  >
                    {/* &#x20B9;{formattedNumber(item?.totalCumulativeAmount)} */}
                    {formattedNumber(item?.totalCumulativeAmount)}
                  </Typography>
                </>
              )}
            </div>
          </Grid>

          <Grid
            item
            xs={6}
            sx={{ borderLeft: "2px solid", borderColor: "#E6E6E6" }}
            style={{ padding: "24px 0px 24px 0px" }}
          >
            <div className="paper-sections">
              <div style={{ display: "flex", alignItems: "center" }}>
                <DropIcon color="#0067AC" height="24" width="24" />
                <Typography
                  sx={{
                    fontSize: 16,
                    fontWeight: 400,
                    color: "#666666",
                    marginLeft: "8px",
                  }}
                >
                  Product
                </Typography>
              </div>
              <Typography
                sx={{
                  fontSize: 18,
                  fontWeight: 600,
                  color: "#1A1A1A",
                  marginTop: "8px",
                }}
              >
                {item?.schemeProducts}
              </Typography>
            </div>
          </Grid>
        </Grid>
        <hr className="paper-hr" />
        <div className="paper-sections" style={{ marginTop: "24px" }}>
          <Grid container>
            <Grid item xs="auto">
              <Typography
                sx={{ fontSize: 16, fontWeight: 400, color: "#666666" }}
              >
                Total Points
              </Typography>
              <Typography
                sx={{ fontSize: 20, fontWeight: 600, color: "#1A1A1A" }}
              >
                {/* {formattedNumber(Number(item?.eligibleAmount))} Points */}
                {Number(item?.eligibleAmount)} Points
              </Typography>
            </Grid>

            {item?.isredeemed && (
              <Grid item xs={true}>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <div style={{ textAlign: "center" }}>
                    <CheckCircleOutlineIcon
                      sx={{ height: "24px", width: "24px", color: "#65A200" }}
                    />
                    <Typography
                      sx={{ fontSize: 16, fontWeight: 600, color: "#65A200" }}
                    >
                      Redeemed
                    </Typography>
                  </div>
                </div>
              </Grid>
            )}

            {!item?.isredeemed && !inactive && (
              <Grid item xs={true} style={{ alignContent: "center" }}>
                <div
                  className="redeem-text"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  Click Here
                </div>
              </Grid>
            )}
          </Grid>
        </div>
      </Paper>
    </Grid>
  );
};

export default SchemeCard;
