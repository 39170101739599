import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function userRedemptionReducer(
  state = initialState.redemption,
  action
) {
  switch (action.type) {
    case types.INIT_REDEMPTION_LOADING: {
      return { ...state, isLoading: true };
    }
    case types.STORE_REDEMPTION_SUCCESS: {
      return { ...state, data: action.payload || [], isLoading: false };
    }
    case types.STORE_REDEMPTION_ERROR: {
      return { ...state, isLoading: false };
    }
    default:
      return state;
  }
}
