import { springEndPoint, node } from "./../config";
import auth from "../modules/auth";
const host = springEndPoint.host;
const adminHost = node.host;

class authApi {
  static login(userParams) {
    return fetchWithInterceptor(`${adminHost}/login`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(userParams),
    })
      .then((response) => auth.verifyResponse(response))
      .then((json) => json)
      .catch((err) => err);
  }

  static sendOtp(userParams) {
    return fetchWithInterceptor(`${host}/sendCustomerOtp`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(userParams),
    })
      .then((response) => auth.verifyResponse(response))
      .then((json) => json)
      .catch((err) => err);
  }

  static verifyOtp(userParams) {
    return fetchWithInterceptor(`${host}/verifyCustomerOtp`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(userParams),
    })
      .then((response) => auth.verifyResponse(response))
      .then((json) => json)
      .catch((err) => err);
  }

  static logout() {
    return fetchWithInterceptor(`${host}/logout`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("_token")}`,
      },
      method: "GET",
    })
      .then((response) => auth.verifyResponse(response))
      .then((json) => json)
      .catch((err) => err);
  }

  static customerLogout() {
    return fetchWithInterceptor(`${host}/customerLogout`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("_token")}`,
      },
      method: "GET",
    })
      .then((response) => auth.verifyResponse(response))
      .then((json) => json)
      .catch((err) => err);
  }


  static forgot(userParams) {
    return fetchWithInterceptor(`${adminHost}/forgotPassword`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(userParams),
    })
      .then((response) => auth.verifyResponse(response))
      .then((json) => json)
      .catch((err) => err);
  }
  static token(authToken) {
    return fetchWithInterceptor(`${host}/volo/${authToken}/reset`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    })
      .then((response) => auth.verifyResponse(response))
      .then((json) => json)
      .catch((err) => err);
  }
  static reset(authToken, userParams) {
    return fetchWithInterceptor(`${host}/volo/${authToken}/reset`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(userParams),
    })
      .then((response) => auth.verifyResponse(response))
      .then((json) => json)
      .catch((err) => err);
  }
  static change(userParams) {
    return fetchWithInterceptor(`${host}/volo/change`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("_userId")}`,
      },
      method: "POST",
      body: JSON.stringify(userParams),
    })
      .then((response) => auth.verifyResponse(response))
      .then((json) => json)
      .catch((err) => err);
  }
  static ssoLogin(accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);

    headers.append("Content-Type", "application/json");

    let options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify({}),
    };
    return fetchWithInterceptor(`${adminHost}/sso/generateToken`, options)
      .then((response) => auth.verifyResponse(response))
      .then((json) => json)
      .catch((err) => err);
  }

  static getSmsDetails(userParams) {
    return fetchWithInterceptor(
      `${host}/user_sms_details?unique_id=${userParams}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "GET",
      }
    )
      .then((response) => auth.verifyResponse(response))
      .then((json) => json)
      .catch((err) => err);
  }
}

export default authApi;
