import { Box, Grid, Paper, Tab, Tabs, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import makeStyles from "@mui/styles/makeStyles";
import React, { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { openErrorSnackbar } from "../../actions";
import { toPascalCase } from "../../utils";
import MenuBar from "../menubar";
import "./dashboard.css";
import CircularProgressWrapper from "../../components/global/CircularProgressWrapper";

import { useNavigate } from "react-router-dom";
import { useMainHook } from "./dashboard.hook";
import SchemeCard from "../../components/dashboard_revamp/schemeCard";
import { isSchemeActive } from "./dashboard.utils";

const useStyles = makeStyles((theme) => ({
  noSchemePaper: {
    width: "100%",
    height: "30vh",
    marginTop: "24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:768px)");
  const [value, setValue] = useState(0);
  const { schemeList, isLoading } = useMainHook();

  function TabPanel(props) {
    const { children, value, index, classes, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`dashboard-tabpanel-${index}`}
        aria-labelledby={`dashboard-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </div>
    );
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onSchemaClick = (offerid) => {
    return navigate(`/scheme/${offerid}`);
  };

  const { activeSchemes, expiredSchemes } = useMemo(() => {
    let activeSchemes = [];
    let expiredSchemes = [];
    schemeList.map((scheme) => {
      if (isSchemeActive(scheme?.redemption_end_date)) {
        activeSchemes.push(scheme);
      } else {
        expiredSchemes.push(scheme);
      }
    });
    return { activeSchemes, expiredSchemes };
  }, [schemeList]);

  return (
    <div>
      <MenuBar sideBar title="Dashboard" />
      <div className="dashboard-base-parent-div">
        <Grid container>
          <Typography
            sx={{ fontSize: 32, fontWeight: "bold", color: "#1A1A1A" }}
          >
            Dashboard
          </Typography>
        </Grid>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={value} onChange={handleChange} aria-label="Dashboard">
            <Tab
              label={toPascalCase("Active")}
              className="tab-label"
              id={0}
              aria-controls="dashboard-tabpanel-0"
            />
            <Tab
              label={toPascalCase("Expired")}
              className="tab-label"
              id={1}
              aria-controls="dashboard-tabpanel-1"
            />
          </Tabs>
        </Box>
        <CircularProgressWrapper isLoading={!!isLoading}>
          <TabPanel value={value} index={0}>
            {activeSchemes.length === 0 ? (
              <Paper className={classes.noSchemePaper} elevation={3}>
                <Typography
                  sx={{ fontSize: 15, fontWeight: "bold", color: "#666666" }}
                >
                  No Active Schemes Found
                </Typography>
              </Paper>
            ) : (
              <Grid container className="dashboard-container" spacing={3}>
                {activeSchemes.map((item) => {
                  return (
                    <SchemeCard
                      key={item.offerid}
                      item={item}
                      onSchemaClick={onSchemaClick}
                      inactive={false}
                    />
                  );
                })}
              </Grid>
            )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {expiredSchemes.length === 0 ? (
              <Paper className={classes.noSchemePaper} elevation={3}>
                <Typography
                  sx={{ fontSize: 15, fontWeight: "bold", color: "#666666" }}
                >
                  No Expired Schemes Found
                </Typography>
              </Paper>
            ) : (
              <Grid container className="dashboard-container" spacing={3}>
                {expiredSchemes.map((item) => {
                  return (
                    <SchemeCard
                      key={item.offerid}
                      item={item}
                      onSchemaClick={() => {
                        dispatch(
                          openErrorSnackbar({
                            message: "Selected offer has expired",
                          })
                        );
                        return;
                      }}
                      inactive={true}
                    />
                  );
                })}
              </Grid>
            )}
          </TabPanel>
        </CircularProgressWrapper>
      </div>
    </div>
  );
};

export default Dashboard;
