import React, { useEffect, useRef, useState } from "react";
import DialogComponent from "../../components/global/dialog";
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  ListItemIcon,
  MenuItem,
  Select,
  TextField,
  Typography,
  colors,
} from "@mui/material";
import RODetailsComponent from "../../components/scheme/RODetailsComponent";
import authApi from "../../api/redemption";
import { useNavigate, useParams } from "react-router-dom";
import { openErrorSnackbar, openSuccessSnackbar } from "../../actions";
import { useDispatch } from "react-redux";
import useDebounce from "./useDebounce";

import { ERROR_MSGS } from "./scheme.utils";

const INITIAL_FILTER_VALUES = {
  state: null,
  city: null,
  roid: "",
  roname: "",
};
const INITIAL_ERROR_VALUES = {
  state: "",
  city: "",
  roid: "",
  roname: "",
};

const RedemptionModal = ({
  open,
  setOpen,
  isLoading,
  getCustomerSchemeDetails,
  scheme,
}) => {
  const [filterValues, setFilterValues] = useState(INITIAL_FILTER_VALUES);
  const [errorMsgs, setErrorMsgs] = useState(INITIAL_ERROR_VALUES);

  const [selectedRowData, setSelectedRowData] = useState({});
  const [ROData, setROData] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  const navigate = useNavigate();

  const debouncedroNameValue = useDebounce(filterValues?.roname, 500);
  const [initialRender, setInitialRender] = useState(true);

  const { schemeId } = useParams();
  const dispatch = useDispatch();
  const gridApiRef = useRef(null);

  const handleClose = () => {
    setOpen(false);
  };

  const isNumeric = (str) => {
    return /^[0-9]+$/.test(str);
  };

  function capitalize(str) {
    str = str.trim();
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.toLowerCase().slice(1);
  }

  const modalDetails = {
    modalHeader: "Select Retail Outlet for Reward Redemption",
    actionButtonLabel: "Submit",
  };

  const handleRedemptionSubmit = () => {
    if (!selectedRowData?.roid) {
      dispatch(
        openErrorSnackbar({
          message:
            "Please select one Retail Outlet from the list before submitting.",
        })
      );
      return;
    }
    const isRoidPresent = ROData.some(
      (roObj) => roObj.roid === selectedRowData?.roid
    );
    if (!isRoidPresent) {
      dispatch(
        openErrorSnackbar({
          message:
            "Please select one Retail Outlet from the list before submitting.",
        })
      );
      return;
    }
    const obj = {
      offerid: schemeId,
      mobileno: localStorage.getItem("_userId"),
      roid: selectedRowData?.roid,
    };
    if (obj.mobileno && obj.offerid) {
      authApi
        .redeemReward(obj)
        .then((response) => {
          if (response && response?.status === 200) {
            dispatch(
              openSuccessSnackbar({
                message: response?.message,
              })
            );
            getCustomerSchemeDetails();
            handleClose();
            navigate("/dashboard");
          }
        })
        .catch((error) => {
          console.log("error=>", error);
        });
    }
  };

  const onGridReady = (params) => {
    gridApiRef.current = params.api;
    params.api.sizeColumnsToFit();
  };

  const setSelectedRows = () => {
    const selectedRows = gridApiRef.current.getSelectedRows();
    if (!!selectedRows[0]) {
      setSelectedRowData(selectedRows[0]);
    } else {
      setSelectedRowData({});
    }
  };

  const getROdata = ({ state, city, roid, roName }) => {
    // Args will be defaulted to undefined if we do not pass them during func call
    const dataObj = {
      offerId: schemeId,
      state: state === undefined ? filterValues?.state : state,
      city: city === undefined ? filterValues?.city : city,
      roid: roid === undefined ? filterValues?.roid : roid,
      roName: roName === undefined ? filterValues?.roname : roName,
    };
    if (dataObj.offerId) {
      authApi
        .getFilteredROList(dataObj)
        .then((response) => {
          if (response && response?.status === 200) {
            setROData(response?.data?.ros ?? []);
            setStateList(response?.data?.states ?? []);
            setCityList(response?.data?.cities ?? []);
            setSelectedRowData({}); // remove selected row
            gridApiRef?.current?.deselectAll();
          }
        })
        .catch((error) => {
          console.log("error=>", error);
        });
    }
  };

  const removeErrorLogs = (property) => {
    setErrorMsgs((prevErrorMsgs) => ({
      ...prevErrorMsgs,
      [property]: "",
    }));
  };

  const setErrorLogs = (property, value) => {
    setErrorMsgs((prevErrorMsgs) => ({
      ...prevErrorMsgs,
      [property]: value,
    }));
  };

  const handleStateValueChange = (newVal) => {
    if (filterValues?.state === newVal) {
      setErrorLogs("state", ERROR_MSGS.required);
      setFilterValues((prevFilterValues) => ({
        ...prevFilterValues,
        state: null,
      }));
      getROdata({ state: null, city: null });
    } else {
      removeErrorLogs("state");
      setFilterValues((prevFilterValues) => ({
        ...prevFilterValues,
        state: newVal,
      }));
      getROdata({ state: newVal, city: null });
    }
    setFilterValues((prevFilterValues) => ({
      ...prevFilterValues,
      city: null,
    }));
  };

  const handleCityValueChange = (newVal) => {
    if (filterValues?.state === null) {
      setErrorLogs("state", ERROR_MSGS.required);
      return;
    }
    if (filterValues?.city === newVal) {
      setErrorLogs("city", ERROR_MSGS.required);
      setFilterValues((prevFilterValues) => ({
        ...prevFilterValues,
        city: null,
      }));
      getROdata({ city: null });
    } else {
      removeErrorLogs("city");
      setFilterValues((prevFilterValues) => ({
        ...prevFilterValues,
        city: newVal,
      }));
      getROdata({ city: newVal });
    }
  };

  const handleROIDValueChange = (newVal) => {
    if (newVal) {
      if (!isNumeric(newVal)) {
        setErrorLogs("roid", ERROR_MSGS.numeric);
        return;
      }
      if (newVal.length != 6) {
        setErrorLogs("roid", ERROR_MSGS.roidLength);
        return;
      }
      removeErrorLogs("roid");
      getROdata({ roid: newVal });
    } else {
      removeErrorLogs("roid");
      getROdata({ roid: null });
    }
  };

  const handleClearAllFilters = () => {
    setFilterValues(INITIAL_FILTER_VALUES);
    setErrorMsgs(INITIAL_ERROR_VALUES);
    getROdata({ state: null, city: null, roid: null, roName: null });
  };

  const checkIfAnyRowSelected = () => {
    if (gridApiRef.current) {
      const selectedRows = gridApiRef.current.getSelectedRows();
      return selectedRows ? !!selectedRows[0] : false;
    } else {
      return false;
    }
  };

  const handleRonameChange = (value) => {
    const hasOnlyWhitespacesRegex = /^\s*$/;

    if (!!value) {
      if (hasOnlyWhitespacesRegex.test(value)) {
        return;
      }
    }

    setFilterValues((prevFilterValues) => ({
      ...prevFilterValues,
      roname: value,
    }));
  };

  useEffect(() => {
    if (!initialRender) {
      if (debouncedroNameValue) {
        getROdata({ roName: debouncedroNameValue });
      } else {
        getROdata({});
      }
    } else {
      setInitialRender(false);
    }
  }, [debouncedroNameValue]);

  useEffect(() => {
    if (!scheme?.isredeemed) {
      getROdata({});
    }
  }, []);

  return (
    <>
      <DialogComponent
        open={open}
        maxWidth={"lg"}
        fullWidth={true}
        handleClose={handleClose}
        modalDetails={modalDetails}
        onUserActionSubmitHandler={handleRedemptionSubmit}
        isActionButtonDisabled={!checkIfAnyRowSelected()}
      >
        <Grid container spacing={2} sx={{ marginTop: "2px" }}>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth>
              <InputLabel id="state-label" error={!!errorMsgs?.state}>
                State
              </InputLabel>
              <Select
                error={!!errorMsgs?.state}
                labelId="state-label"
                id="state-select"
                value={filterValues?.state ?? ""}
                label="State"
              >
                {stateList.length &&
                  stateList.map((stateDetails, index) => {
                    if (stateDetails?.value) {
                      return (
                        <MenuItem
                          key={index}
                          value={stateDetails?.value}
                          onClick={() => {
                            handleStateValueChange(stateDetails?.value);
                          }}
                          sx={{
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            variant="body1"
                            sx={{
                              flex: 1,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",

                              marginRight: "8px",
                            }}
                          >
                            {capitalize(stateDetails?.value)}
                          </Typography>
                        </MenuItem>
                      );
                    }
                  })}
              </Select>
              <FormHelperText sx={{ fontSize: "10px", color: colors.red[500] }}>
                {!!errorMsgs?.state ? errorMsgs?.state : ""}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth>
              <InputLabel id="city-label" error={!!errorMsgs?.city}>
                City
              </InputLabel>
              <Select
                error={!!errorMsgs?.city}
                labelId="city-label"
                id="city-select"
                value={filterValues?.city ?? ""}
                label="City"
              >
                {cityList.length &&
                  cityList.map((cityDetails, index) => {
                    if (cityDetails?.value) {
                      return (
                        <MenuItem
                          key={index}
                          value={cityDetails?.value}
                          onClick={() => {
                            handleCityValueChange(cityDetails?.value);
                          }}
                          sx={{ justifyContent: "space-between" }}
                        >
                          <Typography
                            variant="body1"
                            sx={{
                              flex: 1,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              marginRight: "8px",
                            }}
                          >
                            {capitalize(cityDetails?.value)}
                          </Typography>
                        </MenuItem>
                      );
                    }
                  })}
              </Select>
              <FormHelperText sx={{ fontSize: "10px", color: colors.red[500] }}>
                {!!errorMsgs?.city ? errorMsgs?.city : ""}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth>
              <TextField
                error={!!errorMsgs?.roid}
                id="ROID-text"
                label="SAPCC/ROID"
                helperText={!!errorMsgs?.roid ? errorMsgs?.roid : "Optional"}
                FormHelperTextProps={{
                  sx: {
                    fontSize: "10px",
                  },
                }}
                type="number"
                inputProps={{ maxLength: 6 }} // not working
                value={filterValues?.roid}
                onChange={(e) => {
                  const limitedValue = e.target.value.slice(0, 6);
                  setFilterValues((prevFilterValues) => ({
                    ...prevFilterValues,
                    roid: limitedValue,
                  }));
                  handleROIDValueChange(limitedValue);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth>
              <TextField
                error={!!errorMsgs?.roName}
                id="ROName-text"
                label="RO Name"
                helperText={
                  !!errorMsgs?.roName ? errorMsgs?.roName : "Optional"
                }
                FormHelperTextProps={{
                  sx: {
                    fontSize: "10px",
                  },
                }}
                value={filterValues?.roname}
                onChange={(e) => {
                  handleRonameChange(e.target.value);
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ justifyContent: "flex-end" }}>
          <Grid item xs={12} md={2} sx={{ textAlign: "end" }}>
            <Button
              onClick={handleClearAllFilters}
              variant="text"
              color="error"
              sx={{ textTransform: "capitalize" }}
            >
              {"Clear All"}
            </Button>
          </Grid>
        </Grid>

        <Grid container sx={{ marginTop: "10px" }}>
          <Grid item xs={12}>
            <RODetailsComponent
              onGridReady={onGridReady}
              rowData={ROData}
              onSelectionChanged={setSelectedRows}
            />
          </Grid>
        </Grid>
      </DialogComponent>
    </>
  );
};

export default RedemptionModal;
