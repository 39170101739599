import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Card,
  CardContent,
  TextField,
  Backdrop,
  CircularProgress,
  Box,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import "./signin.css";
import { sendOtp, verifyOtp } from "../../actions";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import MenuBar from "../menubar";
import {
  allowOnlyNumbers,
  allowPincodeOrOtp,
  isValidMobileNumber,
  isValidVehicleNumber,
} from "../../utils";
import authApi from "../../api/login";

const useStyles = makeStyles({
  customerRegistrationCard: {
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "80px 15px 20px",
  },
});

const INITIAL_ERROR = {
  mobileOrVehicleNumber: "",
  otp: "",
};

const SignIn = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { uniqueid } = useParams();
  const loginState = useSelector((state) => state.user);
  const [mobileOrVehicleNumber, setMobileOrVehicleNumber] = useState("");
  const [offerid, setOfferId] = useState("");
  const [otp, setOtp] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [error, setError] = useState(INITIAL_ERROR);
  const [showTimer, setShowTimer] = useState(false);
  const initialSeconds = 300;
  const countdownRef = useRef(initialSeconds);
  const spanRef = useRef(null);

  useEffect(() => {
    if (uniqueid) {
      authApi
        .getSmsDetails(uniqueid)
        .then((smsDetails) => {
          smsDetails?.data?.mobile_number &&
            setMobileOrVehicleNumber(smsDetails?.data?.mobile_number);

          if (smsDetails?.data?.offer_id) {
            setOfferId(smsDetails?.data?.offer_id);
            localStorage.setItem("_offerid", smsDetails?.data?.offer_id);
          }
        })
        .catch((error) => {
          console.log("error=>", error);
        });
    }
  }, [uniqueid]);

  useEffect(() => {
    let intervalId;
    if (showTimer) {
      intervalId = setInterval(() => {
        countdownRef.current -= 1;
        if (countdownRef.current <= 0) {
          clearInterval(intervalId);
          setShowTimer(false);
        }
        if (spanRef.current) {
          spanRef.current.textContent = `${Math.floor(
            countdownRef.current / 60
          )}:${
            countdownRef.current % 60 < 10
              ? `0${countdownRef.current % 60}`
              : countdownRef.current % 60
          }`;
        }
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [showTimer]);

  const startTimer = () => {
    if (!showTimer) {
      countdownRef.current = initialSeconds;
      if (spanRef.current) {
        spanRef.current.textContent = initialSeconds;
      }
      setShowTimer(true);
    }
  };

  const handleSendOtp = () => {
    let isError = false;
    let errorObj = Object.assign({}, INITIAL_ERROR);

    if (!mobileOrVehicleNumber) {
      isError = true;
      errorObj.mobileOrVehicleNumber = "This field is Required.";
    } else if (
      !isValidMobileNumber(mobileOrVehicleNumber) &&
      !isValidVehicleNumber(mobileOrVehicleNumber)
    ) {
      isError = true;
      errorObj.mobileOrVehicleNumber =
        "Please enter valid mobile or vehicle number.";
    } else {
      isError = false;
      errorObj.mobileOrVehicleNumber = "";
    }

    if (!!isError) {
      setError(errorObj);
      return;
    } else {
      const payload = {
        userId: uniqueid,

        offerid: offerid,
      };
      dispatch(sendOtp(payload));
    }
  };

  useEffect(() => {
    if (loginState?.sendOtpSuccess) {
      setIsOtpSent(true);
      startTimer();
    }

    if (loginState?.loginSuccess) {
      localStorage.setItem("_token", loginState.response.userDTO.userToken);
      localStorage.setItem(
        "_userName",
        loginState.response.userDTO.data.customer_name
      );
      localStorage.setItem(
        "_userId",
        loginState.response.userDTO.data.mobile_no
      );
      // localStorage.setItem(
      //   "_vehicleNumber",
      //   loginState.response.userDTO.data?.vehicles[0]?.registration_no
      // );
      localStorage.setItem(
        "_userData",
        JSON.stringify(loginState.response.userDTO.data)
      );
      localStorage.setItem("_userUniqueId", uniqueid);
      navigate("/dashboard");
    }
  }, [loginState]);

  const handleVerifyOtp = () => {
    let isError = false;
    let errorObj = Object.assign({}, INITIAL_ERROR);
    if (!otp) {
      isError = true;
      errorObj.otp = "Otp is Required.";
    } else if (otp.length !== 6) {
      isError = true;
      errorObj.otp = "Please enter a valid OTP of 6 digits!";
    } else {
      isError = false;
      errorObj.otp = "";
    }

    if (!!isError) {
      setError(errorObj);
      return;
    } else {
      const payload = {
        userId: mobileOrVehicleNumber,
        otp: otp,
        offerid: offerid || localStorage.getItem("_offerid"),
      };
      dispatch(verifyOtp(payload));
    }
  };

  const handleOtpChange = (event) => {
    setError(INITIAL_ERROR);
    const value = event.target.value;
    if (allowPincodeOrOtp(value)) {
      setOtp(value);
    }
  };

  const handleMobileOrVehicleChange = (e) => {
    setOtp("");
    setIsOtpSent(false);
    setError(INITIAL_ERROR);
    if (allowOnlyNumbers(e.target.value)) {
      setMobileOrVehicleNumber(e.target.value);
    }
  };

  return (
    <>
      <MenuBar sideBar={false} title="Signin" />
      <Box className={`${classes.customerRegistrationCard} sign-in-container`}>
        <Card elevation={3} className="sign-in-card">
          <CardContent>
            <div className="sign-in-form">
              <div className="sign-in-header">Sign In</div>
              <TextField
                label="Mobile Number"
                value={mobileOrVehicleNumber}
                type="tel"
                inputProps={{
                  maxLength: 10,
                }}
                disabled={true} // need to disble it
                onChange={handleMobileOrVehicleChange}
                error={!!error.mobileOrVehicleNumber}
                helperText={
                  !!error.mobileOrVehicleNumber
                    ? error.mobileOrVehicleNumber
                    : ""
                }
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleSendOtp}
                disabled={!mobileOrVehicleNumber || isOtpSent}
              >
                Send OTP
              </Button>
              {isOtpSent && (
                <>
                  <TextField
                    label="OTP"
                    value={otp}
                    onChange={handleOtpChange}
                    inputProps={{
                      maxLength: 6,
                    }}
                    type="tel"
                    error={!!error.otp}
                    helperText={!!error.otp ? error.otp : ""}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleVerifyOtp}
                    disabled={!otp}
                  >
                    Verify OTP
                  </Button>
                  {showTimer ? (
                    <p>
                      Please wait <span ref={spanRef}>{initialSeconds}</span>{" "}
                      minutes to resend OTP
                    </p>
                  ) : (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleSendOtp}
                    >
                      Resend OTP
                    </Button>
                  )}
                </>
              )}
            </div>
          </CardContent>
        </Card>
        <Backdrop open={loginState.loginPending || false}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  );
};

export default SignIn;
