import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import amber from "@mui/material/colors/amber";
import green from "@mui/material/colors/green";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import bpclLogo from "../../assets/images/logo.png";
import "./menubar.css";
import { logout } from "../../actions/login";
import makeStyles from "@mui/styles/makeStyles";
import backgroundImage from "../../assets/images/header.svg";
import { useDispatch } from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";
import auth from "../../modules/auth";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  bpclLogo: {
    height: 100,
    marginTop: "1.8em",
    width: 80,
    zIndex: 1300,
  },
  menuButton: {
    marginLeft: "8px !important",
  },
  title: {
    flexGrow: 1,
    marginLeft: "10px !important",
  },
  appBar: {
    top: 0,
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      marginLeft: 0,
    },
    height: "69px",
    [theme.breakpoints.up("xs")]: {
      backgroundColor: "red",
    },
    [theme.breakpoints.up("md")]: {
      backgroundImage: `url(${backgroundImage})`,
      backgroundRepeat: "repeat",
    },
    boxShadow: "none",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: 240,
      flexShrink: 0,
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: 230,
    marginTop: 69,
    position: "absolute",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  fab: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  hide: {
    display: "none",
  },
  minWidth: {
    minWidth: 400,
  },
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: "#1976d2",
  },
  warning: {
    backgroundColor: amber[700],
  },

  message: {
    display: "flex",
    alignItems: "center",
  },
  tableContainer: {
    paddingTop: 15,
    paddingBottom: 15,
  },
  menuToolBar: {
    width: "100%",
  },
}));

const MenuBar = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:600px)");
  const [state, setState] = useState({
    open: false,
    userEmail: "BPCL User",
    anchorEl: null,
    setOpen: false,
    menubar: null,
  });
  const { userLogoutInitiated = false } = props.user || {};
  const navigate = useNavigate();
  const location = useLocation();

  const schemeRegistrationFlag = location.pathname.split("/").includes("csr");

  useEffect(() => {
    if (
      !auth.isAuthenticated() &&
      !schemeRegistrationFlag &&
      !location.pathname.split("/").includes("si")
    ) {
      navigate("/si");
    }
  }, []);

  const { anchorEl } = state;
  const open = Boolean(anchorEl);
  if (!classes.appBar.includes("appBar")) {
    classes.appBar = classes.appBar + " appBar";
  }
  if (!classes.title.includes("appTitle")) {
    classes.title = classes.title + " appTitle";
  }

  const handleMenu = (event) => {
    setState((prevFormData) => ({
      ...prevFormData,
      anchorEl: event.currentTarget,
    }));
  };

  const logoutHandler = () => {
    const unique_id = localStorage.getItem("_userUniqueId");
    dispatch(
      logout(() => {
        navigate(unique_id ? "/si/" + unique_id : "/si/");
      })
    );
  };

  const handleOutsideClick = () => {
    setState((prevFormData) => ({
      ...prevFormData,
      anchorEl: null,
    }));
  };

  return (
    <AppBar position="fixed" className={`${classes.appBar} header-menubar`}>
      <Toolbar className={classes.menuToolBar}>
        <img src={bpclLogo} alt="BPCL Logo" className={classes.bpclLogo} />
        <Typography variant="h6" className={classes.title}>
          {props.title}
        </Typography>
        {props.sideBar && (
          <>
            <div
              style={{
                paddingLeft: 16,
                display: "flex",
                alignItems: "center",
                fontSize: 18,
              }}
            >
              <>
                {!isMobile && (
                  <div>
                    Welcome{" "}
                    <b>{props.userName || localStorage.getItem("_userName")}</b>
                  </div>
                )}
                <IconButton
                  edge="start"
                  aria-owns={open ? `menu-appbar` : null}
                  aria-haspopup="true"
                  className={classes.menuButton}
                  color="inherit"
                  aria-label="menu"
                  onClick={handleMenu}
                >
                  <AccountCircle />
                </IconButton>
              </>
            </div>
            <Menu
              id={`menu-appbar`}
              keepMounted
              anchorEl={anchorEl}
              open={open}
              onClick={handleOutsideClick}
            >
              {/* {location.pathname === "/dashboard" ? (
                <MenuItem onClick={() => navigate("/profile")}>
                  My Profile
                </MenuItem>
              ) : (
                <MenuItem onClick={() => navigate("/dashboard")}>
                  Dashboard
                </MenuItem>
              )} */}
              {location.pathname === "/dashboard" ? null : (
                <MenuItem onClick={() => navigate("/dashboard")}>
                  Dashboard
                </MenuItem>
              )}

              <MenuItem
                onClick={() => logoutHandler()}
                disabled={userLogoutInitiated}
              >
                Logout
              </MenuItem>
            </Menu>
          </>
        )}
        <div> &nbsp;&nbsp;</div>
      </Toolbar>
    </AppBar>
  );
};

export default MenuBar;
