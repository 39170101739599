import { Box, Grid, Paper } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import makeStyles from "@mui/styles/makeStyles";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import CircularProgressWrapper from "../../components/global/CircularProgressWrapper";
import RewardClaimComponent from "../../components/scheme/RewardClaimComponent";
import SchemeDetailsComponent from "../../components/scheme/SchemeDetailsComponent";
import SlabEligibilityComponent from "../../components/scheme/SlabEligibilityComponent";
import TransactionDetailsComponent from "../../components/scheme/TransactionDetailsComponent";
import MenuBar from "../menubar";
import RedemptionModal from "./RedemptionModal";
import { useMainHook } from "./scheme.hook";
import "./schemes.css";

const useStyles = makeStyles((theme) => ({}));

const Scheme = () => {
  const isMobile = useMediaQuery("(max-width:899px)");
  const [value, setValue] = useState(0);
  const { selectedScheme, getCustomerSchemeDetails, isLoading } = useMainHook();
  const [open, setOpen] = useState(false);

  const handleRedeem = (offerid) => {
    setOpen(true);
  };

  function TabPanel(props) {
    const { children, value, index, classes, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`schemes-tabpanel-${index}`}
        aria-labelledby={`schemes-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </div>
    );
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <MenuBar sideBar title="Scheme" />
      <div className="scheme-base-parent-div">
        <Paper elevation={4} className="base-paper">
          <CircularProgressWrapper isLoading={!!isLoading}>
            <Grid container className="upper-container">
              <Grid item xs={12} sm={12} md={8}>
                <SchemeDetailsComponent scheme={selectedScheme} />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                sx={
                  isMobile
                    ? {
                        borderBottom: "2px solid #E6E6E6",
                      }
                    : {
                        borderBottom: "2px solid #E6E6E6",
                        borderLeft: "2px solid #E6E6E6",
                      }
                }
              >
                <SlabEligibilityComponent
                  value={value}
                  handleChange={handleChange}
                  scheme={selectedScheme}
                />
              </Grid>
            </Grid>
            <Grid
              container
              className="lower-container"
              direction={isMobile ? "column-reverse" : "row"}
            >
              <Grid item xs={12} sm={12} md={8}>
                <TransactionDetailsComponent scheme={selectedScheme} />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                sx={
                  isMobile
                    ? { borderBottom: "2px solid #E6E6E6" }
                    : { borderLeft: "2px solid #E6E6E6" }
                }
              >
                <RewardClaimComponent
                  scheme={selectedScheme}
                  handleRedeem={handleRedeem}
                />
              </Grid>
            </Grid>
          </CircularProgressWrapper>
        </Paper>
      </div>
      <RedemptionModal
        open={open}
        setOpen={setOpen}
        isLoading={isLoading}
        getCustomerSchemeDetails={getCustomerSchemeDetails}
        scheme={selectedScheme}
      />
    </>
  );
};

export default Scheme;
