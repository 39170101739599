import * as types from "./actionTypes";
import authApi from "../api/signup";
import loginApi from "../api/login"
import { openSuccessSnackbar, openErrorSnackbar } from "./snackbar";

export const signup = (userParams, handleClose) => {
  return (dispatch) => {
    dispatch(userSignupPending());
    return authApi
      .schemeRegister(userParams) // earlier was signup(userParams)
      .then((userResponse) => {
        if (!userResponse.status) {
          dispatch(
            userSignupError(
              "No internet, Please check you internet connection !!",
              false
            )
          );
          dispatch(
            openErrorSnackbar({
              nessage:
                "We're having trouble connecting to our servers at the moment. Please try again in a few minutes.",
            })
          );
          handleClose();
        } else if (userResponse.status === 200) {
          dispatch(userSignupSuccess(userResponse));
          dispatch(openSuccessSnackbar(userResponse));
          handleClose?.();
        } else {
          if(userResponse?.type !== 'otp'){
            handleClose();
          }
          dispatch(
            userSignupError(userResponse.message, userResponse.status === 504)
          );
          dispatch(openErrorSnackbar(userResponse));
        }
      })
      .catch((error) => {
        dispatch(userSignupFailed(error));
      });
  };
};

export const sendSignUpOtp = (payload, successCb, errorCb) => {
  return (dispatch) => {
    return loginApi
      .sendOtp(payload)
      .then((userResponse) => {
        if (!userResponse.status) {
          errorCb();
          dispatch(openErrorSnackbar(userResponse));
        } else if (userResponse.status === 200) {
          dispatch(openSuccessSnackbar(userResponse));
          successCb();
        } else {
          errorCb();
          dispatch(openErrorSnackbar(userResponse));
        }
      })
      .catch((error) => {
        errorCb();
        console.log("Error: ", error);
      });
  };
};

export const updateProfile = (userParams) => {
  return (dispatch) => {
    dispatch(userSignupPending());
    return authApi
      .updateProfile(userParams)
      .then((userResponse) => {
        if (!userResponse.status) {
          dispatch(
            userSignupError(
              "No internet, Please check you internet connection !!",
              false
            )
          );
          dispatch(
            openErrorSnackbar({
              nessage:
                "We're having trouble connecting to our servers at the moment. Please try again in a few minutes.",
            })
          );
        } else if (userResponse.status === 200) {
          dispatch(userSignupSuccess(userResponse));
          dispatch(openSuccessSnackbar(userResponse));
        } else {
          dispatch(
            userSignupError(userResponse.message, userResponse.status === 504)
          );
          dispatch(openErrorSnackbar(userResponse));
        }
      })
      .catch((error) => {
        dispatch(userSignupFailed(error));
      });
  };
};

export const getConsentDetails = (userParams) => {
  return (dispatch) => {
    dispatch(userSignupPending());
    return authApi
      .getConsentDetails(userParams)
      .then((userResponse) => {
        if (!userResponse.status) {
          dispatch(
            userSignupError(
              "No internet, Please check you internet connection !!",
              false
            )
          );
          dispatch(
            openErrorSnackbar({
              nessage:
                "We're having trouble connecting to our servers at the moment. Please try again in a few minutes.",
            })
          );
        } else if (userResponse.status === 200) {
          dispatch(getConsentSuccess(userResponse));
        } else {
          dispatch(
            userSignupError(userResponse.message, userResponse.status === 504)
          );
          dispatch(openErrorSnackbar(userResponse));
        }
      })
      .catch((error) => {
        dispatch(userSignupFailed(error));
      });
  };
};

export function userSignupPending() {
  return {
    type: types.SIGNUP_INITIATED,
  };
}
export function userSignupSuccess(user) {
  return {
    type: types.SIGNUP_SUCCESS,
    user: user.message,
  };
}
export function userSignupError(error, signupNotAllowed) {
  return {
    type: types.SIGNUP_INVALID_CREDS,
    error,
    signupNotAllowed,
  };
}
export function userSignupFailed(error) {
  return {
    type: types.SIGNUP_FAILURE,
    error,
  };
}
export function getConsentSuccess(user) {
  return {
    type: types.GET_CONSENT_SUCCESS,
    user: user,
  };
}
