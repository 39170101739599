import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSchemeDetails, openErrorSnackbar } from "../../actions";
import authApi from "../../api/redemption";

import { useNavigate, useParams } from "react-router-dom";
import { isSchemeActive, serializeSchema } from "./scheme.utils";

export const useMainHook = () => {
  const dispatch = useDispatch();
  const { schemeId } = useParams();
  const navigate = useNavigate();
  const [selectedScheme, setSelectedScheme] = useState(null);
  const {
    data: { schemeData, productList },
    isLoading,
  } = useSelector((state) => state.redemption);

  const getCustomerSchemeDetails = () => {
    const payload = {
      mobile_no: localStorage.getItem("_userId"),
      // vehicle_no: localStorage.getItem("_vehicleNumber"),
    };
    dispatch(getSchemeDetails(payload));
  };

  useEffect(() => {
    if (!!isLoading) {
      getCustomerSchemeDetails();
    } else {
      const schemeList = serializeSchema(schemeData, productList);
      setSelectedScheme(
        schemeList.find((scheme) => {
          return scheme?.offerid === Number(schemeId);
        })
      );

      const isSelectedSchemeActive = schemeList.some((scheme) => {
        return (
          Number(schemeId) === scheme.offerid &&
          isSchemeActive(scheme.redemption_end_date)
        );
      });

      if (!isSelectedSchemeActive) {
        navigate("/dashboard", { replace: true });
        dispatch(
          openErrorSnackbar({
            message: "Selected offer has Expired. Please select an active one.",
            // message: "Unauthorized access to an Expired Offer",
          })
        );
      }
    }
  }, [schemeData]);

  return {
    dispatch,
    productList,
    isLoading,
    selectedScheme,
    getCustomerSchemeDetails,
  };
};
