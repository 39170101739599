const springEndPoint = {
  host: "https://admin-api.bpclcloud9.com",
};

const onboarding = {
      "host":"https://onboarding-api.bpclcloud9.com/volo"
}

const node = {
  host: 'https://metahos-com-bulkupload.azurewebsites.net',
};

export { node, springEndPoint, onboarding };
