const moment = require("moment");

export const formatDate = (date) => {
  if (!!date) {
    return moment.utc(date).format("DD-MMM-YYYY");
  } else {
    return "";
  }
};

export const serializeSchema = (schemeState, _productList) => {
  return schemeState.map(
    ({
      scheme,
      activeSlab,
      activeSlabRewardValue,
      isredeemed,
      totalvolume,
      totalamount,
      redemptiondate,
      firstTransactionDate,
      transactionsList,
    }) => {
      const activeSlabIndex = scheme?.slabs.findIndex(
        (slab) => slab?.slab_id === activeSlab
      );
      const _activeSlab = scheme?.slabs[activeSlabIndex];

      const productsList = !scheme?.products?.trim()
        ? []
        : scheme?.products
            ?.trim()
            .split(",")
            .map((value) => Number(value.trim()));

      const products = productsList.map((productID) => {
        const prodObj = _productList.find((item) => {
          return item.local_prod_id === productID;
        });
        return prodObj ? prodObj.prod_name : [];
      });

      const regex = /^\s*,?\s*$/;
      const schemeProducts = regex.test(products?.join(", ").trim())
        ? ""
        : products?.join(", ").trim();

      return {
        offerid: scheme?.offerid,
        offername: scheme?.offername,
        slab_type: scheme?.slab_type,
        slabs: scheme?.slabs,
        effectivefrom: formatDate(scheme?.effectivefrom),
        effectivetill: formatDate(scheme?.effectivetill),
        redemption_end_date: scheme?.redemption_end_date,
        activeSlab: _activeSlab,
        activeSlabIndex: activeSlabIndex,
        schemeProducts: schemeProducts,
        eligibleAmount: activeSlabRewardValue,
        totalCumulativeFueling: totalvolume || "",
        totalCumulativeAmount: totalamount || "",
        transactionsList,
        isredeemed,
        redemptionDate: redemptiondate,
        firstTransactionDate,
      };
    }
  );
};

export const isSchemeActive = (lastDate) => {
  const targetDate = new Date(lastDate);  // target date
  const now = new Date();  // current date in UTC
  // Convert current date in UTC to IST (UTC +5:30)
  const offset = 5.5 * 60 * 60 * 1000; // offset in milliseconds
  const currentISTDate = new Date(now.getTime() + offset);
  return targetDate >= currentISTDate;
};
