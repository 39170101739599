import * as types from "./actionTypes";
import authApi from "../api/login";
import { openSuccessSnackbar, openErrorSnackbar } from "./snackbar";

export function login(userParams) {
  return function (dispatch) {
    dispatch(userLoginPending());
    return authApi
      .login(userParams)
      .then((userResponse) => {
        if (!userResponse.status) {
          dispatch(
            userLoginError(
              "No internet, Please check you internet connection !!",
              false
            )
          );
        } else if (userResponse.status === 200) {
          dispatch(userLoginSuccess(userResponse.userDTO));
        } else {
          dispatch(
            userLoginError(userResponse.message, userResponse.status === 504)
          );
        }
      })
      .catch((error) => {
        dispatch(userLoginFailed(error));
      });
  };
}

export function verifyOtp(userParams) {
  return function (dispatch) {
    dispatch(userLoginPending());
    return authApi
      .verifyOtp(userParams)
      .then((userResponse) => {
        if (!userResponse.status) {
          dispatch(
            userLoginError(
              "We're having trouble connecting to our servers at the moment. Please try again in a few minutes.",
              false
            )
          );
          dispatch(
            openErrorSnackbar({
              nessage:
                "We're having trouble connecting to our servers at the moment. Please try again in a few minutes.",
            })
          );
        } else if (userResponse.status === 200) {
          dispatch(userLoginSuccess(userResponse));
        } else {
          dispatch(
            userLoginError(userResponse.message, userResponse.status === 504)
          );
          dispatch(openErrorSnackbar(userResponse));
        }
      })
      .catch((error) => {
        dispatch(userLoginFailed(error));
      });
  };
}

export function sendOtp(userParams) {
  return function (dispatch) {
    dispatch(userLoginPending());
    return authApi
      .sendOtp(userParams)
      .then((userResponse) => {
        if (!userResponse.status) {
          dispatch(
            userLoginError(
              "No internet, Please check you internet connection !!",
              false
            )
          );
          dispatch(openErrorSnackbar(userResponse));
        } else if (userResponse.status === 200) {
          dispatch(sendOtpSuccess(userResponse));
          dispatch(openSuccessSnackbar(userResponse));
        } else {
          dispatch(
            userLoginError(userResponse.message, userResponse.status === 504)
          );
          dispatch(openErrorSnackbar(userResponse));
        }
      })
      .catch((error) => {
        dispatch(userLoginFailed(error));
      });
  };
}

export function forgotPasswordTokenCreation(userParams) {
  return function (dispatch) {
    dispatch(forgotPasswordPending());
    return authApi
      .forgotPasswordTokenCreation(userParams)
      .then((userResponse) => {
        if (userResponse.status === "SUCCESS") {
          dispatch(forgotPasswordSuccess(userResponse));
        } else {
          dispatch(forgotPasswordError(userResponse));
        }
      })
      .catch((error) => {
        dispatch(forgotPasswordError(error));
      });
  };
}

export function reset(userParams) {
  return function (dispatch) {
    dispatch(resetPasswordPending());
    return authApi
      .reset(userParams)
      .then((userResponse) => {
        if (userResponse.status && userResponse.status === "SUCCESS") {
          dispatch(resetPasswordSuccess(userResponse));
        } else dispatch(resetPasswordError(userResponse));
      })
      .catch((error) => {
        dispatch(resetPasswordError(error));
      });
  };
}
export function logout(onSuccess) {
  return function (dispatch) {
    dispatch(userLogoutPending());
    return (
      authApi
        .customerLogout()
        .then((userResponse) => {
          if (userResponse.status && userResponse.status === 200) {
            dispatch(userLogoutSuccess(userResponse));
          } else dispatch(userLogoutError(userResponse));
        })
        .catch((error) => {
          dispatch(userLogoutError(error));
        })
        .finally(() => {
          localStorage.clear();
          onSuccess?.();
        })
    );
  };
}
export function validate(userParams) {
  return function (dispatch) {
    dispatch(validateTokenPending());
    return authApi
      .validate(userParams)
      .then((userResponse) => {
        if (userResponse.status === 200) {
          dispatch(validateTokenSuccess(userResponse));
        } else {
          dispatch(validateTokenError(userResponse));
        }
      })
      .catch((error) => {
        dispatch(validateTokenError(error));
      });
  };
}

export function ssoLogin(userParams) {
  return function (dispatch) {
    dispatch(userLoginPending());
    if (userParams.accessToken) {
      return authApi
        .ssoLogin(userParams.accessToken)
        .then((userResponse) => {
          if (!userResponse.status) {
            dispatch(
              userLoginError(
                "No internet, Please check you internet connection !!",
                false
              )
            );
          } else if (userResponse.status === 200) {
            dispatch(userLoginSuccess(userResponse.userDTO));
          } else dispatch(userLoginError(userResponse.message, false));
        })
        .catch((error) => {
          dispatch(userLoginFailed(error));
        });
    }
  };
}

export function forgot(userParams, enqueueSnackbar, successCb) {
  return function (dispatch) {
    dispatch(forgotPasswordPending());
    return authApi
      .forgot(userParams)
      .then((userResponse) => {
        if (userResponse.status === 200) {
          successCb();
          dispatch(forgotPasswordSuccess(userResponse));
          enqueueSnackbar?.(
            userResponse?.message ||
              "Check Your Mail for Resetting the Password",
            {
              variant: "success",
            }
          );
        } else {
          dispatch(forgotPasswordError(userResponse));
          enqueueSnackbar?.(userResponse?.message, {
            variant: "error",
          });
        }
      })
      .catch((error) => {
        dispatch(forgotPasswordError(error));
      });
  };
}

export function userLoginPending() {
  return {
    type: types.LOGIN_INITIATED,
  };
}
export function userLoginSuccess(user) {
  return {
    type: types.LOGIN_SUCCESS,
    user,
  };
}
export function userLoginError(error, loginNotAllowed) {
  return {
    type: types.LOGIN_INVALID_CREDS,
    error,
    loginNotAllowed,
  };
}
export function userLoginFailed(error) {
  return {
    type: types.LOGIN_FAILURE,
    error,
  };
}
export function forgotPasswordPending() {
  return {
    type: types.REQUEST_PASSWORD_INITIATED,
  };
}
export function forgotPasswordSuccess(user) {
  return {
    type: types.REQUEST_PASSWORD_SUCCESS,
    user,
  };
}
export function forgotPasswordError(user) {
  return {
    type: types.REQUEST_PASSWORD_FAILURE,
    user,
  };
}
export function resetPasswordPending() {
  return {
    type: types.RESET_PASSWORD_INITIATED,
  };
}
export function resetPasswordSuccess(user) {
  return {
    type: types.RESET_PASSWORD_SUCCESS,
    user,
  };
}
export function resetPasswordError(user) {
  return {
    type: types.RESET_PASSWORD_FAILURE,
    user,
  };
}
export function changePasswordPending() {
  return {
    type: types.CHANGE_PASSWORD_INITIATED,
  };
}
export function changePasswordSuccess(user) {
  return {
    type: types.CHANGE_PASSWORD_SUCCESS,
    user,
  };
}
export function changePasswordError(user) {
  return {
    type: types.CHANGE_PASSWORD_FAILURE,
    user,
  };
}
export function userLogoutPending() {
  return {
    type: types.LOGOUT_INITIATED,
  };
}
export function userLogoutSuccess(user) {
  return {
    type: types.LOGOUT_SUCCESS,
    user,
  };
}
export function userLogoutError(user) {
  return {
    // type: types.LOGOUT_SUCCESS,
    type: types.LOGIN_FAILURE,
    user,
  };
}
export function validateTokenPending() {
  return {
    type: types.VALIDATE_TOKEN_INITIATED,
  };
}
export function validateTokenSuccess(user) {
  return {
    type: types.VALIDATE_TOKEN_SUCCESS,
    user,
  };
}
export function validateTokenError(error) {
  return {
    type: types.VALIDATE_TOKEN_FAILURE,
    error,
  };
}
export function sendOtpSuccess(response) {
  return {
    type: types.SEND_OTP_SUCCESS,
    response,
  };
}
export function updateOtpSuccess(data) {
  return function (dispatch) {
    dispatch({
      type: types.UPDATE_SEND_OTP_SUCCESS,
      data,
    });
  };
}
