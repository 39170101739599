import React from "react";
import ReactDOM from "react-dom";
import reduxStore from "./store";
import { Provider } from "react-redux";
const parseUrl = require("parse-url");

import { registerFetchInterceptor } from "./api/helper";
import GenerateReceipt from "./Receipt";
import GenerateNewReceipt from "./NewReceipt";
import CustomerRedemption from "./CustomerRedemption";
import Feedback from "./Feedback";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { PersistGate } from "redux-persist/integration/react";

const urlData = parseUrl(window.location.href);
const theme = createTheme();
registerFetchInterceptor();

const renderUI = () => {
  if (urlData?.pathname?.includes("/cs/")) {
    return <CustomerRedemption />;
  } else if (urlData?.pathname?.includes("/fbk")) {
    return <Feedback />;
  } else if (!urlData?.query?.t) {
    return <GenerateNewReceipt />;
  } else {
    return <GenerateReceipt />;
  }
};
ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={reduxStore.store}>
        <PersistGate loading={null} persistor={reduxStore.persistor}>
          {renderUI()}
        </PersistGate>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
