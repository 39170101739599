import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SignIn from "./signin";
import SignUp from "./signup/CustomerRegistration";
// import Dashboard from "./dashboard";
import Dashboard from "./dashboard_revamp";
import Scheme from "./scheme";
import SnackbarComponent from "./SnackbarComponent";
import CustomerSchemeRegistration from "./signup/CustomerSchemeRegistration";

const CustomerRedemption = () => {
  return (
    <>
      <Router basename="/cs">
        <Routes>
          <Route path="/" exact element={<SignUp />} />
          <Route path="/si/:uniqueid" element={<SignIn />} />
          <Route path="/si" element={<SignIn />} />
          <Route path="/customerRegistration" element={<SignUp />} />
          {/* <Route path="/sr" element={<SignUp />} />
          <Route path="/sr/:uniqueid" element={<SignUp />} /> */}
          <Route path="/csr" element={<CustomerSchemeRegistration />} />
          <Route
            path="/csr/:uniqueid"
            element={<CustomerSchemeRegistration />}
          />
          {/* <Route path="/profile" element={<SignUp />} /> */}
          <Route path="/dashboard" element={<Dashboard />} />
          {/* <Route path="/dashboard" element={<Dashboard />} /> */}
          <Route path="/scheme/:schemeId" element={<Scheme />} />
        </Routes>
        <SnackbarComponent />
      </Router>
    </>
  );
};

export default CustomerRedemption;
