import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSchemeDetails } from "../../actions";
import "./dashboard.css";
import { serializeSchema } from "./dashboard.utils";

export const useMainHook = () => {
  const dispatch = useDispatch();
  const {
    data: { schemeData, productList },
    isLoading,
  } = useSelector((state) => state.redemption);

  useEffect(() => {
    getCustomerSchemeDetails();
  }, []);

  const getCustomerSchemeDetails = () => {
    const payload = {
      mobile_no: localStorage.getItem("_userId"),
    };
    dispatch(getSchemeDetails(payload));
  };

  const schemeList = useMemo(() => {
    return schemeData ? serializeSchema(schemeData, productList) : [];
  }, [schemeData]);

  return {
    dispatch,
    productList,
    schemeList,
    isLoading,
    getCustomerSchemeDetails,
  };
};
