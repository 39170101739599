import * as types from "./actionTypes";
import authApi from "../api/redemption";

export const getSchemeDetails = (userParams, onSuccess) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.INIT_REDEMPTION_LOADING });
      const response = await authApi.getSchemeDetails(userParams);
      const productListResponse = await authApi.getProductList();
      if (
        response.data &&
        response.status === 200 &&
        productListResponse.data &&
        productListResponse.status === 200
      ) {
        dispatch({
          type: types.STORE_REDEMPTION_SUCCESS,
          payload: {
            schemeData: response.data,
            productList: productListResponse?.data?.result,
          },
        });
        onSuccess?.(response.data);
      } else {
        dispatch({ type: types.STORE_REDEMPTION_ERROR });
      }
    } catch (error) {
      console.log("!!error", error);
      dispatch({ type: types.STORE_REDEMPTION_ERROR, payload: error });
    }
  };
};
