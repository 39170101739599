export const toPascalCase = (str) => {
  if (!str) {
    return "";
  }
  return str
    .split(" ") // Split the string into an array of words
    .map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() // Capitalize the first letter of each word and lowercase the rest
    )
    .join(" "); // Join the words back into a string with spaces
};

export const allowOnlyNumbers = (str) => /^[0-9]*$/.test(str);

export const allowPincodeOrOtp = (str) => /^[0-9]{0,6}$/.test(str);

export const isValidMobileNumber = (value) => /^[6-9]\d{9}$/.test(value);

export const isValidVehicleNumber = (value) =>
  /^[A-Za-z]{1,2}[0-9]{1,2}[A-Za-z]{0,2}[0-9]{4}$/.test(value);

export const isValidBharatSeriesNumber = (value) =>
  /^[0-9]{2}BH[0-9]{4}[A-HJ-NP-Z]{1,2}$/.test(value);

export const formattedNumber = (number) => {
  if (!!number) {
    if (typeof number === "number") {
      return number.toFixed(2);
    } else if (typeof number === "string") {
      return number;
    }
  }
  return "";
};

export const DLregex =
  /^(([A-Z]{2}[0-9]{2})( )|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$/;
export const alphanumericWhitespaceRegex = /^[a-zA-Z0-9\s]*$/;
export const allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
export const consecutiveNewLineRegex = /\n\n/;
