import { springEndPoint, node } from "./../config";
import auth from "../modules/auth";
const host = springEndPoint.host;
const adminHost = node.host;

class authApi {
  static getSchemeDetails({ mobile_no }) {
    const queryString = new URLSearchParams({
      mobile_no,
    }).toString();

    return fetchWithInterceptor(`${host}/schemeDetails?${queryString}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("_token")}`,
      },
      method: "GET",
    })
      .then((response) => auth.verifyResponse(response))
      .then((json) => json)
      .catch((err) => err);
  }

  static redeemReward(userParams) {
    return fetchWithInterceptor(`${host}/redeem_reward`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("_token")}`,
      },
      method: "POST",
      body: JSON.stringify(userParams),
    })
      .then((response) => auth.verifyResponse(response))
      .then((json) => json)
      .catch((err) => err);
  }

  static getProductList() {
    return fetchWithInterceptor(`${host}/product_list`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("_token")}`,
      },
      method: "GET",
    })
      .then((response) => auth.verifyResponse(response))
      .then((json) => json)
      .catch((err) => err);
  }

  static getFilteredROList({ offerId, state, city, roid, roName }) {
    let apiPath = `${host}/get-filtered-ros/${offerId}`;

    if (state || city || roid || roName) {
      apiPath += "?";
      if (state) apiPath += `state=${state}&`;
      if (city) apiPath += `city=${city}&`;
      if (roid) apiPath += `roid=${roid}&`;
      if (roName) apiPath += `roname=${roName}&`;
    }

    return fetchWithInterceptor(apiPath, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("_token")}`,
      },
      method: "GET",
    })
      .then((response) => auth.verifyResponse(response))
      .then((json) => json)
      .catch((err) => err);
  }
}

export default authApi;
