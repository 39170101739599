import * as LoginActions from "../actions/login";
// import {showSnackbar} from '../utils/snackbarWrapper';
class auth {
  static isAuthenticated() {
    const userToken = localStorage.getItem("_token");
    const userId = localStorage.getItem("_userId");
    if (!!userToken && !!userId) return true;
    else return false;
  }
  static logOut() {
    localStorage.clear();
    // window.location = "/login";
    LoginActions.logout();
    return true;
  }
  static verifyResponse(response) {
    if (response.status === 200) {
      if (response.statusText === "No Content") {
        return { status: "completed" };
      }
      return response.json();
    } else if (response.status === 202 || response.status === 204) {
      if (
        response.statusText === "No Content" ||
        response.statusText === "Accepted" ||
        response.statusText === ""
      ) {
        return { status: "completed" };
      }
      return response.json();
    } else if (response.status === 401) {
      const unique_id = localStorage.getItem("_userUniqueId");
      localStorage.clear();

      window.location = unique_id ? "/cs/si/" + unique_id : "/cs/si/";
      LoginActions.logout();
      return response.json();
    } else if (response.status === 403) {
      // showSnackbar('Permission denied!', {variant: 'error'})
      return response.json();
    } else if (response.status === 500) {
      //   window.location = "/500?ref=server-error";
      return response.json();
    } else {
      return response.json();
    }
  }
}
export default auth;
