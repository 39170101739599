import CircleIcon from "@mui/icons-material/Circle";
import { Box, Grid, Tab, Tabs, Typography } from "@mui/material";
import React from "react";
import { toPascalCase } from "../../utils";

const SlabEligibilityComponent = (props) => {
  function TabPanel(props) {
    const { children, value, index, classes, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`schemes-tabpanel-${index}`}
        aria-labelledby={`schemes-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </div>
    );
  }

  const SlabIconRenderer = (index) => {
    if (index < props?.scheme?.activeSlabIndex) {
      return <CircleIcon sx={{ color: "#9CFC00", height: 10, width: 10 }} />;
    } else if (index === props?.scheme?.activeSlabIndex) {
      return <CircleIcon sx={{ color: "#FCDB00", height: 10, width: 10 }} />;
    } else {
      return null;
    }
  };

  const NextSlabEligibilyRenderer = (index) => {
    if (index < props?.scheme?.activeSlabIndex) {
      return true;
    } else if (index === props?.scheme?.activeSlabIndex) {
      return false;
    } else {
      return false;
    }
  };

  return (
    <>
      <Box sx={{ borderBottom: "2px solid #E6E6E6" }}>
        <Tabs
          value={props?.value}
          onChange={props?.handleChange}
          aria-label="Dashboard"
          sx={{ padding: "24px 24px 0px 24px" }}
        >
          {props?.scheme?.slabs?.map((slab, index) => {
            return (
              <Tab
                key={index}
                label={toPascalCase(slab.slab_name)}
                icon={SlabIconRenderer(index)}
                iconPosition="end"
                className="tab-label"
                sx={{ minHeight: 48, textTransform: "capitalize" }}
                id={index}
                aria-controls={`dashboard-tabpanel-${index}`}
              />
            );
          })}
        </Tabs>
      </Box>
      {props?.scheme?.slabs?.map((slab, index) => {
        return (
          <TabPanel key={index} value={props?.value} index={index}>
            <Grid
              container
              direction={"column"}
              className="slab-eligibility-contents"
              spacing={1}
            >
              <Grid item className="slab-eligibility-content-data">
                <Typography
                  sx={{
                    fontSize: 16,
                    fontWeight: 700,
                    color: "#666666",
                  }}
                >
                  Range:{" "}
                  {props?.scheme?.slab_type === "volume"
                    ? `${slab?.slab_min}L - ${slab?.slab_max}L`
                    : `₹${slab?.slab_min} - ₹${slab?.slab_max}`}
                </Typography>
              </Grid>
              <Grid item className="slab-eligibility-content-data">
                <Typography
                  sx={{
                    fontSize: 16,
                    fontWeight: 700,
                    color: "#666666",
                  }}
                >
                  Slab Points: {slab.slab_value}
                </Typography>
              </Grid>
              {index === props?.scheme?.slabs?.length - 1 ? null : (
                <Grid item className="slab-eligibility-content-data">
                  <Typography
                    sx={{
                      fontSize: 16,
                      fontWeight: 400,
                      color: "#666666",
                    }}
                  >
                    Keep fueling your vehicle to avail more points
                  </Typography>
                </Grid>
              )}
              {index === props?.scheme?.slabs?.length - 1 ||
              !NextSlabEligibilyRenderer(index) ? null : (
                <Grid item className="slab-eligibility-content-data">
                  <Typography
                    sx={{
                      fontSize: 16,
                      fontWeight: 400,
                      color: "#1A1A1A",
                      paddingBottom: "20px",
                    }}
                  >
                    You are eligible for Slab {index + 2}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </TabPanel>
        );
      })}
    </>
  );
};

export default SlabEligibilityComponent;
