import CircularProgress from "@mui/material/CircularProgress";
import React from "react";

/**
 * CircularProgressWrapper component renders the div layout of the application with optional loading indicator and other props.
 *
 * @param {React.ReactNode} props.children - The child components to be rendered within the layout.
 * @param {boolean} props.isLoading - Indicates whether the application is in a loading state.
 * @param {Object} props - Additional props that can be passed to customize the layout.
 * @returns {JSX.Element} - The `CircularProgressWrapper` component is returning a layout structure with a div
 * content area with a CircularProgress component displayed when isLoading is true and the children when it is false.
 */
const CircularProgressWrapper = ({ children, isLoading }) => {
  return (
    <React.Fragment>
      <div>
        {!!isLoading ? (
          <div
            style={{
              flexGrow: 1,
              width: "100%",
              alignItems: "center",
              minHeight: "calc(40vh - 70px)",
              boxSizing: "border-box",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CircularProgress sx={{ color: "#087dc1" }} />
          </div>
        ) : (
          children
        )}
      </div>
    </React.Fragment>
  );
};

export default CircularProgressWrapper;
